import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const Modal = ({ show, handleClose, courseNumber }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className="modal-content">
        <h2>Запись на курс</h2>
        <p>Отсканируйте QR-код для записи на курс:</p>
        <div className="qr-code-container-modal">
          <img
            src="assets/google-sheet-qr.png" 
            alt="Записаться через Телеграм"
            className="qr-code-image-modal"
          />
        </div>
        <p>Номер курса: <strong>{courseNumber}</strong></p>
        <div className="button-container-flex">
          <button type="button" className="orange-button2" onClick={handleClose}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};


const App = () => {
  const [stage, setStage] = useState('start');
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [description, setDescription] = useState({});
  const [courses, setCourses] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [contactInfo, setContactInfo] = useState({ phone: '', email: '' });
  const [selectedClassLevel, setSelectedClassLevel] = useState(null);
  const [showCourseDetails, setShowCourseDetails] = useState(false); 
  const [courseImage, setCourseImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 


  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    resetInactivityTimeout();

    window.addEventListener('click', resetInactivityTimeout);
    window.addEventListener('keypress', resetInactivityTimeout);
    window.addEventListener('mousemove', resetInactivityTimeout);

    return () => {
      clearTimeout(inactivityTimeoutRef.current); 
      window.removeEventListener('click', resetInactivityTimeout);
      window.removeEventListener('keypress', resetInactivityTimeout);
      window.removeEventListener('mousemove', resetInactivityTimeout);
    };
  }, []);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutRef.current); 
    inactivityTimeoutRef.current = setTimeout(() => {
      setStage('start');
      setHistory([]);
      setShowModal(false); 
      setShowCourseDetails(false); 
    }, 60000);
  };

  const handleBack = () => {
    let previousStage = history.pop();

    while (previousStage === 'questions') {
      previousStage = history.pop(); 
    }
  
    if (previousStage) {
      setStage(previousStage);
      setHistory([...history]);
    } else {
      setStage('start'); 
    }
  };

  const changeStage = (newStage) => {
    setHistory([...history, stage]);
    setStage(newStage);
  };

  const handleStart = async () => {
    try {
      const res = await axios.get('https://tekhnopark-info.atp-fivt.org:5000/start');
      setMessage(res.data.message);
      changeStage('role');
    } catch (error) {
      console.error('Error starting the session', error);
      setMessage('Ошибка при запуске сессии');
    }
  };

  const handleRole = async (role) => {
    try {
      const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/role', { role });
      setMessage(res.data.message);
      changeStage('class');
    } catch (error) {
      console.error('Error selecting role', error);
      setMessage('Ошибка при выборе роли');
    }
  };

  const handleClass = async (classLevel) => {
    try {
      const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/class', { class_level: classLevel });
      setSelectedClassLevel(classLevel);
      setMessage(res.data.message);
      changeStage('option');
    } catch (error) {
      console.error('Error selecting class', error);
      setMessage('Ошибка при выборе класса');
    }
  };

  const handleOption = async (option) => {
    setMessage('Загрузка...');

    try {
      if (option === 'view_courses') {
        const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/class', { class_level: selectedClassLevel });
        setCourses(res.data.courses);
        changeStage('courses');
      } else if (option === 'get_recommendation') {
        const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/recommendation', { class_level: selectedClassLevel });
        setQuestions(res.data.questions);
        setAnswers(res.data.answers);
        setCurrentQuestion(0);
        changeStage('questions');
      }
    } catch (error) {
      console.error('Error handling option', error);
      setMessage('Ошибка при обработке запроса');
    }
  };

  const handleAnswer = async (answerIdx) => {
    let updatedAnswers = [...answers]; 
    updatedAnswers[currentQuestion] = answerIdx; 
  
    setAnswers(updatedAnswers);
  
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      try {
        const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/submit_answers', {
          answers: updatedAnswers, 
        });
  
        if (res.data.recommendations) {
          setRecommendedCourses(res.data.recommendations); 
          changeStage('recommended_courses'); 
        } else {
          console.error('No recommendations received.');
        }
      } catch (error) {
        console.error("Ошибка при отправке ответа", error);
        setMessage("Ошибка при отправке ответа");
      }
    }
  };

  const getCourseImage = (courseNumber) => `/assets/${courseNumber}.jpeg`;

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prevInfo => ({ ...prevInfo, [name]: value }));
  };

  const handleSignUp = async () => {
    try {
      const courseNumber = selectedCourse;
      const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/sign_up', {
        course_number: courseNumber,
        contact_info: contactInfo
      });
      setMessage(res.data.message);
      setShowModal(false);
      setShowSuccessMessage(true); 
      setTimeout(() => {
        setShowSuccessMessage(false); 
      }, 3000);
    } catch (error) {
      console.error('Error signing up for course', error);
      setMessage('Ошибка при записи на курс');
    }
  };

  const getCourseDescription = async (courseNumber) => {
    try {
      const res = await axios.post('https://tekhnopark-info.atp-fivt.org:5000/course_description', { course_number: courseNumber });
      setDescription(res.data);
      setSelectedCourse(courseNumber);
      setCourseImage(getCourseImage(courseNumber));
      setShowCourseDetails(true);
    } catch (error) {
      console.error('Ошибка получения описания курса', error);
    }
  };

  const CourseDetails = () => {
    return (
      <div className="course-details-container">
        <h2>{description['Название курса']}</h2>
        <img src={courseImage} alt="Course" className="course-details-image" />
        <div className="course-details-content">
          <p>{description['Описание курса']}</p>
          <p><strong>Руководитель курса:</strong> {description['Руководитель курса']}</p>
          <p><strong>Возраст:</strong> {description['Возраст']}</p>
          <p><strong>Расписание:</strong> {description['Расписание']}</p>
          <p><strong>Аудитория:</strong> {description['Аудитория']}</p>
        </div>
        <div className="button-container">
          <button className="orange-button" onClick={handleOpenModal}>
            Записаться на курс
          </button>
        </div>
  
        <button className="back-button" onClick={() => setShowCourseDetails(false)}>
          Назад
        </button>

        <Modal
          show={showModal}
          handleClose={handleCloseModal}
          courseNumber={selectedCourse} 
        />
      </div>
    );
  };
  

  const backgroundStyle = {
    backgroundImage: 'url("assets/background.png")',
    backgroundSize: 'cover',  
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#0D2038',  
    minHeight: '100vh',
    minWidth: '100vw', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};


  return (
    <div style={backgroundStyle}>
      {stage !== 'start' && history.length > 0 && !showCourseDetails &&(
        <button
          className="back-button"
          onClick={handleBack}
          style={{ position: 'absolute', bottom: '20px', left: '20px' }}
        >
          Назад
        </button>
      )}
      
      {showCourseDetails ? (
        <CourseDetails />
      ) : (
        <div className="main-content">
          {stage === 'start' && (
  <div className="bot-container">
  <div className="bubble bottom">{'Привет!'}</div>
  <img
    src='assets/bot.svg'
    alt="ЦифраБот"
    className="bot-image"
  />
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <button className="orange-button big-button" onClick={handleStart}>
      Пообщаться с Цифработом
    </button>
  </div>
  <div className="qr-code-container">
                <img
                  src="assets/telegram-qr.png" 
                  alt="Перейти в Телеграм"
                  className="qr-code-image"
                />
                <p className="qr-code-caption">Открыть в Телеграм</p>
              </div>
</div>
)}
       {showSuccessMessage && (
        <div className="success-message">
          Запись на курс прошла успешно!
        </div>
      )}

      {stage === 'role' && (
        <div className="bot-container">
         <div className="bubble bottom">{message}</div>
          <img 
            src='assets/bot.svg'
            alt="ЦифраБот" 
            className="bot-image"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="orange-button big-button"  onClick={() => handleRole('schoolboy')}>Я школьник</button>
            <button className="orange-button big-button" onClick={() => handleRole('parent')}>Я родитель</button>
          </div>
        </div>
      )}
      
      {stage === 'class' && (
        <div className="bot-container">
          <div className="bubble bottom">{message}</div>
          <img 
            src='assets/bot.svg'
            alt="ЦифраБот" 
            className="bot-image"
          />
          <div className="button-container">
      <button className="orange-button big-button" onClick={() => handleClass(5)}>5 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(6)}>6 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(7)}>7 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(8)}>8 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(9)}>9 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(10)}>10 класс</button>
      <button className="orange-button big-button" onClick={() => handleClass(11)}>11 класс</button>
    </div>
        </div>
      )}
      
      {stage === 'option' && (
        <div className="bot-container">
          <div className="bubble bottom">{message}</div>
          <img 
            src='assets/bot.svg'
            alt="ЦифраБот" 
            className="bot-image"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button className="orange-button big-button" onClick={() => handleOption('view_courses')}>Просмотреть все курсы</button>
          <button className="orange-button big-button" onClick={() => handleOption('get_recommendation')}>Узнать, какой курс тебе подходит</button>
          </div>
        </div>
      )}
      
      {stage === 'questions' && (
  <div className="question-container">
    <h2 class="title">{questions[currentQuestion]}</h2>
    <div className="question-button-container">
      {Array.isArray(answers[currentQuestion]) ? (
        answers[currentQuestion].map((answer, idx) => (
          <button className="question-button" key={idx} onClick={() => handleAnswer(idx)}>
            {answer}
          </button>
        ))
      ) : (
        <p>Нет доступных ответов для данного вопроса.</p>
      )}
    </div>
  </div>
)}


{stage === 'courses' && (
            <div className="courses-container">
              <h2 class="title">Все доступные курсы</h2>
              <div className="courses-grid">
                {courses.map((course, idx) => {
                  // Привязываем изображение к курсу по его номеру
                  const courseImage = getCourseImage(course['Номер']); // Функция для получения изображения по номеру курса

                  return (
                    <div className="course-card" key={idx}>
                      <h3>{course['Название курса']}</h3>
                      <img
                        src={courseImage}
                        alt={course['Название курса']}
                        className="course-image"
                      />
                      <button className="orange-button" onClick={() => getCourseDescription(course['Номер'])}>
                        Подробнее
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

{stage === 'recommended_courses' && (
  <div className="courses-container">
    <h2 class="title">Рекомендованные курсы</h2>
    <div className="courses-grid">
      {recommendedCourses.length > 0 ? (
        recommendedCourses.map((course, idx) => {
          const courseNumber = course.number;
          const courseImage = getCourseImage(courseNumber); // Получаем изображение курса

          return (
            <div className="course-card" key={idx}>
              <h3>{course.name}</h3>
              <img
                src={courseImage}
                alt={course.name}
                className="course-image"
              />
              <button
                className="orange-button"
                onClick={() => getCourseDescription(courseNumber)}
              >
                Подробнее
              </button>
            </div>
          );
        })
      ) : (
        <p>Нет рекомендованных курсов</p>
      )}
    </div>
  </div>
)}

      
        </div>
      )}
      

    </div>
  );
}

export default App;
